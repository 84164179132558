.intro{
    font-size: 30px;
    font-weight: 200;
    margin-top: 10px;
    line-height: 1.25em;
    font-family: var(--body-font);
    width: 80%;
    font-style: italic;
    align-self: center;
    text-align: center;
    transform: translateX(10%);
}